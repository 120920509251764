import { VStack, Input, Button, Container } from "@chakra-ui/react";
import NavBar from "../components/NavBar";
import { useState } from "react";
import { changeHandler } from "../components/inputs/utils";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { roleState, tokenState, usernameState } from "../state/profile";
import { Api, LoginResponseBody } from "../lib/mohawk/Api";

import { AxiosResponse } from "axios";

export default function Login() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [currentTokenState, setTokenState] = useRecoilState(tokenState);

  const [currentUsernameState, setUsernameState] =
    useRecoilState(usernameState);

  const navigate = useNavigate();

  const doLogin = async () => {
    const client = new Api();
    client.instance.defaults.baseURL = "";

    await client.api
      .login({ username, password })
      .then((response: AxiosResponse<LoginResponseBody>) => {
        setTokenState(response.data.token);
        navigate("/");
      });
  };

  return (
    <div>
      <NavBar />
      <Container>
        <VStack>
          <h1>Login</h1>
          <Input
            value={username}
            onChange={changeHandler(setUsername)}
            placeholder="Username"
          />
          <Input
            value={password}
            onChange={changeHandler(setPassword)}
            type="password"
            placeholder="Password"
          />
          <Button onClick={doLogin}>Login</Button>
        </VStack>
      </Container>
    </div>
  );
}
