import { Box, Button, Center, Flex, VStack } from "@chakra-ui/react";
import PixelText from "../PixelText";

export interface Plan {
  id: number;
  name: string;
  description: string;
  max_players: number;
  cpu_cores: number;
  ram_gb: number;
  active_until?: string;
}

export interface PlanBarProps {
  plans: Plan[];
}

export default function PlanBar(props: PlanBarProps) {
  return (
    <Flex direction="column" bg="blue.500" padding="1em">
      <Box width="100%">
        <Center>
          <PixelText size="4em">Plans</PixelText>
        </Center>
      </Box>
      <Box>
        <Flex direction="row" width="100%">
          {props.plans.map((plan) => {
            return (
              <Center flex={1}>
                <VStack
                  borderColor="black"
                  borderStyle="solid"
                  borderWidth="0.1em"
                  borderRadius="0.5em"
                  bg="blue.500"
                  spacing={0}
                >
                  <Box
                    bg="yellow.500"
                    padding="0.25em"
                    borderRadius="0.5em 0.5em 0em 0em"
                  >
                    <PixelText size="2em">{plan.name}</PixelText>
                  </Box>
                  <VStack width="100%" bg="darkBlue.500" padding="0.5em">
                    <Box
                      bg="lightBlue.500"
                      paddingLeft="0.25em"
                      paddingRight="0.25em"
                      borderRadius="0.25em"
                    >
                      <PixelText size="1.5em">
                        {plan.cpu_cores} CPU Cores
                      </PixelText>
                    </Box>
                    <Box
                      bg="lightBlue.500"
                      paddingLeft="0.25em"
                      paddingRight="0.25em"
                      borderRadius="0.25em"
                    >
                      <PixelText size="1.5em">{plan.ram_gb} GB RAM</PixelText>
                    </Box>
                    <Box
                      bg="lightBlue.500"
                      paddingLeft="0.25em"
                      paddingRight="0.25em"
                      borderRadius="0.25em"
                    >
                      <PixelText size="1.5em">
                        {plan.max_players} Players
                      </PixelText>
                    </Box>
                  </VStack>
                  <Box width="100%" bg="darkBlue.500" padding="0.25em">
                    <Center>
                      <Button marginTop="0.5em" marginBottom="0.5em">
                        Purchase Disabled
                      </Button>
                    </Center>
                  </Box>
                </VStack>
              </Center>
            );
          })}
        </Flex>
      </Box>
    </Flex>
  );
}
