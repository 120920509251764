import { Center, HStack, VStack } from "@chakra-ui/react";
import DefaultLayout from "../../layouts/DefaultLayout";
import PixelText from "../../components/PixelText";
import MarketingCard from "../../components/marketing/MarketingCard";
import PlanBar, { Plan } from "../../components/plans/PlanBar";
import { useEffect, useState } from "react";

export default function SevenDaysToDie() {
  const gameID = 1;
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    document.title = "GameCTL | 7 Days to Die";
  }, [setPlans, gameID]);

  return (
    <DefaultLayout>
      <VStack width="100%">
        <Center width="100%">
          <PixelText size="5em">7 Days to Die</PixelText>
        </Center>
        <PlanBar plans={plans} />
        <MarketingCard
          imageUrl="/images/games/7-days-to-die/horde.png"
          imageAlt="A horde of zombies"
          title="Dedicated Servers"
          height="20%"
          width="80%"
          imageFlex={2}
          contentFlex={8}
        >
          <p>
            Join your friends in a race against the clock to defeat hordes of
            zombies.
          </p>
        </MarketingCard>
      </VStack>
    </DefaultLayout>
  );
}
