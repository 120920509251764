import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import PixelText from "../PixelText";

export interface MarketingCardProps {
  imageUrl: string;
  imageAlt: string;
  imageFlex?: number;
  contentFlex?: number;
  title?: string;
  children: React.ReactNode;
  height?: string;
  width?: string;
  imageSide?: "left" | "right";
}

export default function MarketingCard(props: MarketingCardProps) {
  const imageBox = (
    <Box flex={props.imageFlex || 2} height="100%">
      <img
        src={props.imageUrl}
        alt={props.imageAlt}
        width="100%"
        style={{ maxWidth: "100%" }}
      />
    </Box>
  );
  const contentBox = (
    <Box flex={props.contentFlex || 8}>
      <Flex
        flexDirection="column"
        minHeight="100%"
        height="100%"
        flex={8}
        marginLeft={props.imageSide === "right" ? "" : "1em"}
        marginRight={props.imageSide === "left" ? "" : "1em"}
      >
        {props.title !== undefined && (
          <Box
            bg="orange.500"
            padding="0.5em"
            borderRadius="1em 1em 0em 0em"
            flex={1}
            borderColor="black"
            borderStyle="solid"
            borderWidth="0.1em"
          >
            <PixelText size="2em">{props.title}</PixelText>
          </Box>
        )}
        <Box
          flex={8}
          bg="lightBlue.500"
          padding="0.5em"
          borderRadius="0em 0em 1em 1em"
          borderColor="black"
          borderStyle="solid"
          borderWidth="0.1em"
        >
          {props.children}
        </Box>
      </Flex>
    </Box>
  );

  const firstBox = props.imageSide === "right" ? contentBox : imageBox;
  const secondBox = props.imageSide === "right" ? imageBox : contentBox;
  return (
    <Flex
      flexDirection="row"
      bg="blue.500"
      borderRadius="1em"
      padding="1em"
      minHeight={props.height || ""}
      height={props.height || ""}
      width={props.width || ""}
      borderStyle="solid"
      borderColor="grey"
      borderWidth="0.1em"
      //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      boxShadow="rgba(50, 50, 93, 0.25) 0px 3em 6em -1.2em, rgba(0, 0, 0, 0.3) 0px 1.8em 3.6em -1.8em"
    >
      {firstBox}
      {secondBox}
    </Flex>
  );
}
