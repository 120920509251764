import { Box, Container } from "@chakra-ui/react";
import NavBar from "../components/NavBar";

export interface DefaultLayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <Box
      bg="lightBlue.500"
      style={{ height: "100vh", width: "100vw", overflow: "auto" }}
    >
      <NavBar />
      <Container maxW="75%">{props.children}</Container>
    </Box>
  );
}
