import { useRecoilState } from "recoil";
import { usernameState } from "../../state/profile";

export interface OnlyLoggedInProps {
  children: React.ReactNode;
}

export default function OnlyLoggedIn({ children }: OnlyLoggedInProps) {
  const [username, setUsername] = useRecoilState(usernameState);

  if (username !== "") {
    return <>{children}</>;
  }
  return <></>;
}
