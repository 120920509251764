import { Box, HStack, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PixelText from "../PixelText";
import { IconType } from "react-icons";
import OnlyLoggedIn from "../auth/OnlyLoggedIn";

export interface NavBarLinkProps {
  name: string;
  icon: IconType;
  link: string;
  onlyLoggedIn?: boolean;
}

export default function NavBarLink(props: NavBarLinkProps) {
  const link = (
    <Box padding="0.25em" marginLeft="0.25em" marginRight="0.25em" float="left">
      <Box
        borderColor="#8ECAE6"
        borderStyle="solid"
        borderWidth="0.1em"
        borderRadius="0.5em"
        paddingTop="0.25em"
        paddingBottom="0.25em"
        paddingLeft="0.5em"
        paddingRight="0.5em"
      >
        <Link to={props.link}>
          <HStack spacing={2}>
            <Icon fontSize="1.5em" as={props.icon} />
            <PixelText size="1em">
              <span>{props.name}</span>
            </PixelText>
          </HStack>
        </Link>
      </Box>
    </Box>
  );

  if (props.onlyLoggedIn) {
    return <OnlyLoggedIn>{link}</OnlyLoggedIn>;
  } else {
    return link;
  }
}
