import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaServer } from "react-icons/fa6";
import { GiPlagueDoctorProfile, GiRaiseZombie } from "react-icons/gi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useRecoilState } from "recoil";
import { roleState, usernameState } from "../../state/profile";
import { useEffect } from "react";
import PixelText from "../PixelText";
import NavBarLink from "./NavBarLink";

export default function NavBar() {
  const [username, setUsername] = useRecoilState(usernameState);
  const [role, setRole] = useRecoilState(roleState);

  const menuIcons = [
    {
      name: "7 Days to Die",
      icon: GiRaiseZombie,
      link: "/games/7-days-to-die",
    },
  ];

  const isLoggedIn = () => {
    return username !== "";
  };

  const navHeight = 3;

  useEffect(() => {}, [username, role, setUsername, setRole]);

  return (
    <Box
      as={"nav"}
      bg={"darkBlue.500"}
      color={"white"}
      p={4}
      width="100%"
      style={{ marginBottom: "1em", width: "%" }}
      margin="0px"
      padding="0px"
      height={navHeight.toString() + "em"}
    >
      <Flex
        flexDirection="row"
        height={navHeight.toString() + "em"}
        style={{ width: "100%" }}
        margin="0px"
      >
        <HStack
          flexBasis="auto"
          flexGrow={0}
          flexShrink={1}
          padding="0.25em"
          marginLeft="1em"
        >
          <img src="/images/logo.png" width={14.377 * navHeight + "em"} />
          <Link to={"/"}>
            <PixelText style={{ fontSize: "1.5em" }}>GameCTL</PixelText>
          </Link>
          <Box height="100%" width="0.1em" bg="white" />
        </HStack>
        <HStack flexGrow={10} flexShrink={10} flexBasis="auto">
          {menuIcons.map((icon) => (
            <NavBarLink name={icon.name} icon={icon.icon} link={icon.link} />
          ))}
        </HStack>
        <Box flexGrow={0} flexShrink={1} flexBasis="auto">
          <NavBarLink name="Servers" icon={FaServer} link={"/servers"} />
        </Box>
        <Box>
          <Menu colorScheme="black">
            <MenuButton
              color="black"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              Admin
            </MenuButton>
            <MenuList color="black">
              <MenuItem>
                <Link to={"/admin/games"} style={{ width: "100%" }}>
                  Games
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={"/admin/plans"} style={{ width: "100%" }}>
                  Plans
                </Link>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        {isLoggedIn() ? (
          <Box marginLeft="1em" marginRight="1em">
            <NavBarLink
              link="/logout"
              name="Logout"
              icon={GiPlagueDoctorProfile}
            />
          </Box>
        ) : (
          <Box>
            <Link to={"/login"}>Login</Link>
          </Box>
        )}
      </Flex>
    </Box>
  );
}
