import { HStack, VStack } from "@chakra-ui/react";
import PixelText from "../components/PixelText";
import DefaultLayout from "../layouts/DefaultLayout";
import MarketingCard from "../components/marketing/MarketingCard";

export default function Home() {
  return (
    <DefaultLayout>
      <VStack>
        <PixelText size="6em">GameCTL</PixelText>
        <PixelText size="2em">Where gamers come to play.</PixelText>
        <MarketingCard
          imageUrl="/images/dedicated-servers.png"
          imageAlt="Servers in a datacenter"
          title="Dedicated Servers"
          height="20%"
          width="100%"
          imageFlex={2}
          contentFlex={8}
        >
          <p>
            We provide dedicated servers for your favorite games, including 7
            Days to Die.
          </p>
        </MarketingCard>
      </VStack>
    </DefaultLayout>
  );
}
