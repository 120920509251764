import { atom } from "recoil";

export const usernameState = atom({
  key: "usernameState",
  default: "",
});

export const roleState = atom({
  key: "roleState",
  default: -1,
});

export const tokenState = atom({
  key: "tokenState",
  default: "",
});
