import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    orange: {
      500: "#FB8500",
    },
    yellow: {
      500: "#FFB703",
    },
    darkBlue: {
      500: "#023047",
    },
    blue: {
      500: "#219EBC",
    },
    lightBlue: {
      500: "#8ECAE6",
    },
  },
});

export default theme;
